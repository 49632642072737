/*
===============================================================================
    File        =   sign-in-app/src/actions/fetchData.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains actions
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.08.28  Crush Bate         Initial Creation
        -----------------------------------------------------------------------
===============================================================================
*/
import {RESEND_VERIFICATION_REQUEST, RESEND_VERIFICATION_SUCCESS, RESEND_VERIFICATION_ERROR, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR,RESET_STATE,RESEND_COMPANY_CODE_ERROR,RESEND_COMPANY_CODE_REQUEST,RESEND_COMPANY_CODE_SUCCESS} from "../actionTypes/auth";

export function resendVerificationRequest() {
    return {
        type: RESEND_VERIFICATION_REQUEST
    };
}

export function resendVerificationSuccess(data) {
    return {
        type: RESEND_VERIFICATION_SUCCESS,
        payload: data
    };
}

export function resendVerificationError(error) {
    return {
        type: RESEND_VERIFICATION_ERROR,
        payload: error
    };
}

export function forgotPasswordRequest() {
    return {
        type: FORGOT_PASSWORD_REQUEST
    };
}

export function forgotPasswordSuccess(data) {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        payload: data
    };
}

export function forgotPasswordError(error) {
    return {
        type: FORGOT_PASSWORD_ERROR,
        payload: error
    };
}

export function resetPasswordRequest() {
    return {
        type: RESET_PASSWORD_REQUEST
    };
}

export function resetPasswordSuccess(data) {
    return {
        type: RESET_PASSWORD_SUCCESS,
        payload: data
    };
}

export function resetPasswordError(error) {
    return {
        type: RESET_PASSWORD_ERROR,
        payload: error
    };
}

export function resendCompanyCodeRequest() {
    return {
        type: RESEND_COMPANY_CODE_REQUEST
    };
}

export function resendCompanyCodeSuccess(data) {
    return {
        type: RESEND_COMPANY_CODE_SUCCESS,
        payload: data
    };
}

export function resendCompanyCodeError(error) {
    return {
        type: RESEND_COMPANY_CODE_ERROR,
        payload: error
    };
}

export function resetState() {
    return {
        type: RESET_STATE
    };
}
