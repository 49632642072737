import { SignUpSuccess } from "../../../ui-components";
import { useCallback,useMemo } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetState } from "../../../actions/account";
import Loader from "../../../utils/loader/Loader";
import { resendCompanyCode} from "../../../actions/reducers/reducers";

const selectResendCompanyCodeLoading = (state) => {
	return state.account.resendCompanyCodeLoading;
};

function Success({ email, password, firstName }) {
	const navigate = useNavigate();

	const resendCompanyCodeLoading = useSelector((state) =>
		selectResendCompanyCodeLoading(state)
	);

	const dispatch = useDispatch();

	const handleResendCompanyCode = useCallback(
		async (event) => {
			event.preventDefault();

			const resendCompanyCodeBody = {
				email: email,
			};
			resendCompanyCode(dispatch, resendCompanyCodeBody);
		},
		[email, dispatch]
	);

	const navigateToSignIn = useCallback(() => {
		dispatch(resetState());
		navigate("/signin", { state: { email: email, password: password } });
	}, [navigate, dispatch, email, password]);

	const signUpSuccessOverrides = useMemo(() => {
		return {
			SignUpSuccess: {
				height: "100vh",
				width: "50vw",
				overflow: "auto",
				padding: "5px 48px 24px 48px",
			},

			"Welcome Timothy": {
				children: `Welcome ${firstName}`,
			},

			"Just one final step remains to complete your sign-up process. Kindly check your email inbox for a unique company code. We've successfully sent an email containing the code to the address timothy.o.ojo@gmail.com. This code will be needed when you proceed to the sign-in page.":
				{
					children: `Just one final step remains to complete your sign-up process. Kindly check your email inbox for a unique company code. We've successfully sent an email containing the code to the address ${email}. This code will be needed when you proceed to the sign-in page.`,
				},

			Button585734759: {
				onClick: navigateToSignIn,
				children: "Sign In",
			},

			Button585734760: {
				onClick: handleResendCompanyCode,
				children: "Resend Company Code",
			},

			email: email,
			password: password,
		};
	}, [navigateToSignIn, handleResendCompanyCode, email, password, firstName]);

	return (
		<>
			{resendCompanyCodeLoading && (
				<Loader width="50%" height="50%" transform="-50%, -100%" />
			)}
			{<SignUpSuccess overrides={signUpSuccessOverrides}></SignUpSuccess>}
		</>	
);
}

export default Success;
