/*
===============================================================================
    File        =   sign-in-app/src/actionTypes/auth.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains the action types for the auth slice which
                    will be used to define the actions for the auth slice
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.06.18  Moataz Khallaf    Initial Creation
        1.1     2024.08.28  Crush Bate        Added password forgot/reset and
        resend verification
        1.2     2025.01.16  Crush Bate        Add resend company code
        -----------------------------------------------------------------------
===============================================================================
*/
export const GET_AUTH_TOKEN_REQUEST = "GET_AUTH_TOKEN_REQUEST";
export const GET_AUTH_TOKEN_SUCCESS = "GET_AUTH_TOKEN_SUCCESS";
export const GET_AUTH_TOKEN_ERROR = "GET_AUTH_TOKEN_ERROR";

export const VALIDATE_AUTH_TOKEN_REQUEST = "VALIDATE_AUTH_TOKEN_REQUEST";
export const VALIDATE_AUTH_TOKEN_SUCCESS = "VALIDATE_AUTH_TOKEN_SUCCESS";
export const VALIDATE_AUTH_TOKEN_ERROR = "VALIDATE_AUTH_TOKEN_ERROR";

export const SIGN_OUT_REQUEST = "SIGN_OUT_REQUEST";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_ERROR = "SIGN_OUT_ERROR";

export const RESEND_VERIFICATION_REQUEST = "RESEND_VERIFICATION_REQUEST";
export const RESEND_VERIFICATION_SUCCESS = "RESEND_VERIFICATION_SUCCESS";
export const RESEND_VERIFICATION_ERROR = "RESEND_VERIFICATION_ERROR";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const RESEND_COMPANY_CODE_REQUEST = "RESEND_COMPANY_CODE_REQUEST";
export const RESEND_COMPANY_CODE_SUCCESS = "RESEND_COMPANY_CODE_SUCCESS";
export const RESEND_COMPANY_CODE_ERROR = "RESEND_COMPANY_CODE_ERROR";

export const RESET_STATE = "RESET_STATE";