/*
===============================================================================
    File        =   sign-in-app/src/pages/Account/StartHiring/StartHiring.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   SignupLeftPanel Component
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.06.19  Moataz Khallaf    Initial Creation
        -----------------------------------------------------------------------
===============================================================================
*/
import SignupLeftPanel from "../../../ui-components/SignupLeftPanel";

const StartHiring = () => {
	const signupLeftPanelOverrides = {
		SignupLeftPanel: {
			height: "100vh",
			width: "50vw",
		},
		LoginBullet: {
			borderWidth: "1px 0px 1px 0px",

			overrides: {
				"Intuitive and effective": {
					children: "Streamlined candidate selection",
				},

				Check: {
					overrides: {
						Check: {
							paths: [
								{
									d: "M4.243 8.487L0 4.243L1.414 2.829L4.243 5.657L9.899 0L11.314 1.415L4.243 8.485L4.243 8.487Z",
									fill: "rgba(255,255,255,1)", // Changed to white
									fillRule: "nonzero",
									style: {
										transform: "translate(20.83%, 28.44%)",
									},
								},
								{
									d: "M0 11C0 4.925 4.925 0 11 0C17.075 0 22 4.925 22 11C22 17.075 17.075 22 11 22C4.925 22 0 17.075 0 11ZM11 20C9.8181 20 8.64778 19.7672 7.55585 19.3149C6.46392 18.8626 5.47177 18.1997 4.63604 17.364C3.80031 16.5282 3.13738 15.5361 2.68508 14.4442C2.23279 13.3522 2 12.1819 2 11C2 9.8181 2.23279 8.64778 2.68508 7.55585C3.13738 6.46392 3.80031 5.47177 4.63604 4.63604C5.47177 3.80031 6.46392 3.13738 7.55585 2.68508C8.64778 2.23279 9.8181 2 11 2C13.3869 2 15.6761 2.94821 17.364 4.63604C19.0518 6.32387 20 8.61305 20 11C20 13.3869 19.0518 15.6761 17.364 17.364C15.6761 19.0518 13.3869 20 11 20Z",
									fill: "rgba(255,255,255,1)", // Changed to white
									fillRule: "evenodd",
									style: { transform: "translate(0%, 0%)" },
								},
							],
						},
					},
				},
			},
		},

		"Frame 1069": {
			borderWidth: "1px 0px 1px 0px",
			overrides: {
				"Intuitive and effective": {
					children: "Time-saving automation",
				},

				Check: {
					overrides: {
						Check: {
							paths: [
								{
									d: "M4.243 8.487L0 4.243L1.414 2.829L4.243 5.657L9.899 0L11.314 1.415L4.243 8.485L4.243 8.487Z",
									fill: "rgba(255,255,255,1)", // Changed to white
									fillRule: "nonzero",
									style: {
										transform: "translate(20.83%, 28.44%)",
									},
								},
								{
									d: "M0 11C0 4.925 4.925 0 11 0C17.075 0 22 4.925 22 11C22 17.075 17.075 22 11 22C4.925 22 0 17.075 0 11ZM11 20C9.8181 20 8.64778 19.7672 7.55585 19.3149C6.46392 18.8626 5.47177 18.1997 4.63604 17.364C3.80031 16.5282 3.13738 15.5361 2.68508 14.4442C2.23279 13.3522 2 12.1819 2 11C2 9.8181 2.23279 8.64778 2.68508 7.55585C3.13738 6.46392 3.80031 5.47177 4.63604 4.63604C5.47177 3.80031 6.46392 3.13738 7.55585 2.68508C8.64778 2.23279 9.8181 2 11 2C13.3869 2 15.6761 2.94821 17.364 4.63604C19.0518 6.32387 20 8.61305 20 11C20 13.3869 19.0518 15.6761 17.364 17.364C15.6761 19.0518 13.3869 20 11 20Z",
									fill: "rgba(255,255,255,1)", // Changed to white
									fillRule: "evenodd",
									style: { transform: "translate(0%, 0%)" },
								},
							],
						},
					},
				},
			},
		},

		"Frame 1070": {
			borderWidth: "1px 0px 1px 0px",

			overrides: {
				"Intuitive and effective": {
					children: "Enhanced candidate insights",
				},

				Check: {
					overrides: {
						Check: {
							paths: [
								{
									d: "M4.243 8.487L0 4.243L1.414 2.829L4.243 5.657L9.899 0L11.314 1.415L4.243 8.485L4.243 8.487Z",
									fill: "rgba(255,255,255,1)", // Changed to white
									fillRule: "nonzero",
									style: {
										transform: "translate(20.83%, 28.44%)",
									},
								},
								{
									d: "M0 11C0 4.925 4.925 0 11 0C17.075 0 22 4.925 22 11C22 17.075 17.075 22 11 22C4.925 22 0 17.075 0 11ZM11 20C9.8181 20 8.64778 19.7672 7.55585 19.3149C6.46392 18.8626 5.47177 18.1997 4.63604 17.364C3.80031 16.5282 3.13738 15.5361 2.68508 14.4442C2.23279 13.3522 2 12.1819 2 11C2 9.8181 2.23279 8.64778 2.68508 7.55585C3.13738 6.46392 3.80031 5.47177 4.63604 4.63604C5.47177 3.80031 6.46392 3.13738 7.55585 2.68508C8.64778 2.23279 9.8181 2 11 2C13.3869 2 15.6761 2.94821 17.364 4.63604C19.0518 6.32387 20 8.61305 20 11C20 13.3869 19.0518 15.6761 17.364 17.364C15.6761 19.0518 13.3869 20 11 20Z",
									fill: "rgba(255,255,255,1)", // Changed to white
									fillRule: "evenodd",
									style: { transform: "translate(0%, 0%)" },
								},
							],
						},
					},
				},
			},
		},

		"Frame 1071": {
			borderWidth: "1px 0px 1px 0px",

			overrides: {
				"Intuitive and effective": {
					children: "Customizable job requirements",
				},

				Check: {
					overrides: {
						Check: {
							paths: [
								{
									d: "M4.243 8.487L0 4.243L1.414 2.829L4.243 5.657L9.899 0L11.314 1.415L4.243 8.485L4.243 8.487Z",
									fill: "rgba(255,255,255,1)", // Changed to white
									fillRule: "nonzero",
									style: {
										transform: "translate(20.83%, 28.44%)",
									},
								},
								{
									d: "M0 11C0 4.925 4.925 0 11 0C17.075 0 22 4.925 22 11C22 17.075 17.075 22 11 22C4.925 22 0 17.075 0 11ZM11 20C9.8181 20 8.64778 19.7672 7.55585 19.3149C6.46392 18.8626 5.47177 18.1997 4.63604 17.364C3.80031 16.5282 3.13738 15.5361 2.68508 14.4442C2.23279 13.3522 2 12.1819 2 11C2 9.8181 2.23279 8.64778 2.68508 7.55585C3.13738 6.46392 3.80031 5.47177 4.63604 4.63604C5.47177 3.80031 6.46392 3.13738 7.55585 2.68508C8.64778 2.23279 9.8181 2 11 2C13.3869 2 15.6761 2.94821 17.364 4.63604C19.0518 6.32387 20 8.61305 20 11C20 13.3869 19.0518 15.6761 17.364 17.364C15.6761 19.0518 13.3869 20 11 20Z",
									fill: "rgba(255,255,255,1)", // Changed to white
									fillRule: "evenodd",
									style: { transform: "translate(0%, 0%)" },
								},
							],
						},
					},
				},
			},
		},

		"Frame 1072": {
			borderWidth: "1px 0px 1px 0px",

			overrides: {
				"Intuitive and effective": {
					children: "Collaborative hiring process",
				},

				Check: {
					overrides: {
						Check: {
							paths: [
								{
									d: "M4.243 8.487L0 4.243L1.414 2.829L4.243 5.657L9.899 0L11.314 1.415L4.243 8.485L4.243 8.487Z",
									fill: "rgba(255,255,255,1)", // Changed to white
									fillRule: "nonzero",
									style: {
										transform: "translate(20.83%, 28.44%)",
									},
								},
								{
									d: "M0 11C0 4.925 4.925 0 11 0C17.075 0 22 4.925 22 11C22 17.075 17.075 22 11 22C4.925 22 0 17.075 0 11ZM11 20C9.8181 20 8.64778 19.7672 7.55585 19.3149C6.46392 18.8626 5.47177 18.1997 4.63604 17.364C3.80031 16.5282 3.13738 15.5361 2.68508 14.4442C2.23279 13.3522 2 12.1819 2 11C2 9.8181 2.23279 8.64778 2.68508 7.55585C3.13738 6.46392 3.80031 5.47177 4.63604 4.63604C5.47177 3.80031 6.46392 3.13738 7.55585 2.68508C8.64778 2.23279 9.8181 2 11 2C13.3869 2 15.6761 2.94821 17.364 4.63604C19.0518 6.32387 20 8.61305 20 11C20 13.3869 19.0518 15.6761 17.364 17.364C15.6761 19.0518 13.3869 20 11 20Z",
									fill: "rgba(255,255,255,1)", // Changed to white
									fillRule: "evenodd",
									style: { transform: "translate(0%, 0%)" },
								},
							],
						},
					},
				},
			},
		},
	};

	return <SignupLeftPanel overrides={signupLeftPanelOverrides} />;
};

export default StartHiring;
